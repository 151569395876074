<template>
  <div>
    <div class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid
      :key="key"
      :service="service"
      route_name="dynamic_reports">

      <template v-slot:gridThead>
            <vs-th key="id">ID</vs-th>
            <vs-th key="name">{{ $t('relatorios_dinamicos_grid_nome') }}</vs-th>
            <vs-th key="description">{{ $t('relatorios_dinamicos_grid_descricao') }}</vs-th>
          </template>
          <template v-slot:gridActions>
            <vs-th key="actions">{{ $t('Actions') }}</vs-th>
          </template>
          <template v-slot:gridData="props">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
              <vs-td width="5%" key="id">{{ tr.id }}</vs-td>
              <vs-td width="45%" key="name">{{ tr.name }}</vs-td>
              <vs-td width="45%" key="description">{{tr.description}}</vs-td>
              <vs-td width="5%" key="actions">
                <div class="grid grid-cols-3 gap-8 sm:gap-4 md:gap-4">
                  <div class="flex items-center justify-center">

                    <feather-icon
                      v-permission="'dynamic_report_executions.create'"
                      icon="EditIcon"
                      svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                      @click="props.edit(props.data[indextr].id)"
                    />

                    <feather-icon
                      icon="Trash2Icon"
                      svgClasses="h-5 w-5 mb-1 stroke-current text-danger "
                      @click="props.destroy(props.data[indextr])"
                      v-tooltip.top="{
                        content: 'Excluir',
                        delay: { show: 1000, hide: 100 }
                      }"
                    />
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>

    </Grid>

  </div>
</template>

<script>
import DynamicReportService from '@/services/api/DynamicReportService'

export default {
  data: () => ({
    selected: [],
    service: null,
    key: 0
  }),
  methods: {
    addNew() {
      this.$router.push('/dynamic_reports/create')
    }
  },
  beforeMount() {
    this.service = DynamicReportService.build(this.$vs)
  }
}
</script>
