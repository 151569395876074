var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex flex-wrap-reverse items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
            on: { click: _vm.addNew },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-primary" }, [
              _vm._v(_vm._s(_vm.$t("AddNew"))),
            ]),
          ],
          1
        ),
      ]),
      _c("Grid", {
        key: _vm.key,
        attrs: { service: _vm.service, route_name: "dynamic_reports" },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id" }, [_vm._v("ID")]),
                _c("vs-th", { key: "name" }, [
                  _vm._v(_vm._s(_vm.$t("relatorios_dinamicos_grid_nome"))),
                ]),
                _c("vs-th", { key: "description" }, [
                  _vm._v(_vm._s(_vm.$t("relatorios_dinamicos_grid_descricao"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridActions",
            fn: function () {
              return [
                _c("vs-th", { key: "actions" }, [
                  _vm._v(_vm._s(_vm.$t("Actions"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _vm._v(_vm._s(tr.id)),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "45%" } }, [
                      _vm._v(_vm._s(tr.name)),
                    ]),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "45%" } },
                      [_vm._v(_vm._s(tr.description))]
                    ),
                    _c("vs-td", { key: "actions", attrs: { width: "5%" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-3 gap-8 sm:gap-4 md:gap-4",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "dynamic_report_executions.create",
                                    expression:
                                      "'dynamic_report_executions.create'",
                                  },
                                ],
                                attrs: {
                                  icon: "EditIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                },
                                on: {
                                  click: function ($event) {
                                    return props.edit(props.data[indextr].id)
                                  },
                                },
                              }),
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: {
                                      content: "Excluir",
                                      delay: { show: 1000, hide: 100 },
                                    },
                                    expression:
                                      "{\n                      content: 'Excluir',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                    modifiers: { top: true },
                                  },
                                ],
                                attrs: {
                                  icon: "Trash2Icon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 stroke-current text-danger ",
                                },
                                on: {
                                  click: function ($event) {
                                    return props.destroy(props.data[indextr])
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }